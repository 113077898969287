import React, { Component } from "react";

export class MapContainer extends Component {
  render() {
    return (
      <>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.2840867166233!2d74.75490251527175!3d13.332596310460392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbcbbe9b73b38e3%3A0x351cc3f6351200a7!2sPathaakin%20Figurines!5e0!3m2!1sen!2sin!4v1600953252506!5m2!1sen!2sin"
          className="google_map"
          width="100%"
          height="250px"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          title="Map"
        ></iframe>
      </>
    );
  }
}

export default MapContainer;

import YouTube from "react-youtube-embed";
import React from "react";

function YoutubeEmbed() {
  return (
    <div className="container vertical-spacer" style={{ marginTop: 50 }}>
      <h2 className="vertical-spacer-sm" style={{ textAlign: "center" }}>
        A short introduction
      </h2>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="mx-auto">
            <YouTube id="RKjmub7L7oA" />
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}

export default YoutubeEmbed;

import React from "react";

const Footerend = (props) => {
  return (
    <div className="container-fluid footer-copyright">
      <div className="d-flex justify-content-center">
        &copy;&nbsp;Copyright, Pathaakin Figurines&nbsp;
        {new Date().getFullYear()}.
      </div>
    </div>
  );
};

export default Footerend;

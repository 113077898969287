import React from "react";
import Img from "react-cool-img";

function WhatWeDo() {
  return (
    <main style={{ marginTop: 50 }}>
      <div className="container">
        <div className="row">
          <div style={{ textAlign: "center", width: "100%" }}>
            <strong>
              <h1>Pathaakin’s value-chain framework</h1>
            </strong>
          </div>
          <Img
            placeholder={require("../../assets/Icons/loading.svg")}
            src={require("../../assets/Icons/what-we-do.svg")}
            alt="what-we-do"
            debounce={1000}
            width="100%"
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>
              <strong>
                {" "}
                <u>
                  1<sup>st</sup> Phase:
                </u>
              </strong>{" "}
              Our superior vendors dispatch the unpolished raw brass figurines
              and reaches the warehouse. These unpolished brass figurines are
              then moved to the zero vaporizing unit to remove the dark, sticky
              and deeper stains.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <u>
                <strong>
                  {" "}
                  2<sup>nd</sup> Phase:
                </strong>
              </u>{" "}
              The initial Curing process of destaining takes around 45 minutes
              for each raw unpolished brass figurine. Sterilization process is
              done with thermal citric and tartaric acid treatment for the next
              45 minutes till the zero vaporized cleansing is complete. The
              figurines are soaked with abrasive paste for 1 hour, moving to the
              next critical phase.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>
              <u>
                <strong>
                  {" "}
                  3<sup>rd</sup> Phase:
                </strong>{" "}
              </u>
              Figurine surfaces are dried with high quality electric spinning
              scrubber brush passing the two important levels of polish.
            </p>
            <ul>
              <li style={{ listStyleType: "square" }}>
                <p>
                  {" "}
                  Liquid Brass Metal Polish with 12 volts electric power rotary
                  chuck-fixed superior polishing mop wheel buffing pads with
                  14000 rotations per minute.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  {" "}
                  Final Shiner Coating, mirror finishing serves as a protective
                  layer to keep away from the chemical reaction, rust and
                  corrosion, guarding all fluctuating weather conditions. The
                  figurines are engraved with pathaakin logo and sent to the
                  final phase of packing.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <p>
              <strong>
                {" "}
                4<sup>th</sup> Phase:
              </strong>{" "}
              The mirror finished figurine is gently packed with air bubble
              sheets. These sheets cushion in all directions to avoid damages
              while transit. Depending on the weight and size of the figurine,
              3ply and 5ply corrugated carton box is chosen. For the easy and
              convenient inventory tracking, and other supermarket POS (Point of
              Sale), a Universal GS1 EAN 13 GTIN Barcode is assigned for each
              figurine along with QR Code.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3>Crux of the Pathaakin’s 4P value-chain model</h3>
            <ul>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin strives to improve, enhance and enrich each phase of
                  the value chain cluster by a thorough process documentation to
                  match with the global standards.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin maintains high trust and loyalty with all the
                  stakeholders.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin values every customer with a spiritual-driven
                  satisfaction.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin maintains a Centralized Quality Supply (C.Q.S)
                  policy to speed-up the store inventory, enable quick dispatch
                  and delivery.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default WhatWeDo;

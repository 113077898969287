import React from "react";

const about = (props) => (
  <main style={{ marginTop: "80px" }}>
    <div className="container">
      <h1>About Us</h1>
      <p className="text-paragraph ">
        Pathaakin began as an antique trading and logistical venture registered
        with Ministry of Micro, Small and Medium Enterprises (MSME), Government
        of India.
      </p>
      <p className="text-paragraph">
        Pathaakin’s Milestone began with Research on Antique Collectibles and
        Hindu idols way back in 2016. After surveying thousands of tourists and
        NRIs from different parts of the world, it was observed that they
        couldn’t find any quality figurines for Home Décor Requirements. This
        further lead to the incubation (2018) and startup (2019).{" "}
      </p>
      <p className="text-paragraph">
        This is where Pathaakin began its operations to rebrand the generic
        antique collectibles focusing on brass figurines. Pathaakin products
        follow the{" "}
        <a
          href="https://www.wikipedia.org/wiki/Bhakti_movement"
          target="_blank"
          rel="noopener noreferrer"
        >
          saguna
        </a>{" "}
        tatva of vedic sciences apart from{" "}
        <a
          href="https://www.wikipedia.org/wiki/Bhakti_movement"
          target="_blank"
          rel="noopener noreferrer"
        >
          nirguna
        </a>{" "}
        tatva. Today Pathaakin is an emerging brand with a registered trademark
        to deliver high standard leaded rose brass figurines with a combination
        of 5 metals (click for wiki reference{" "}
        <a
          href="https://www.wikipedia.org/wiki/Panchaloha"
          target="_blank"
          rel="noopener noreferrer"
        >
          Panchaloha{" "}
        </a>
        ) namely Gold, Silver, Copper, Zinc and Iron composing{" "}
        <a
          href="https://www.wikipedia.org/wiki/Ferrous"
          target="_blank"
          rel="noopener noreferrer"
        >
          ferrous
        </a>{" "}
        and{" "}
        <a
          href="https://www.wikipedia.org/wiki/Non-ferrous_metal"
          target="_blank"
          rel="noopener noreferrer"
        >
          non-ferrous
        </a>{" "}
        metals. Initially 15 figurines of different gods, goddesses and saints
        are launched. All the Pathaakin products follow the Kaizen process of
        Total Quality Management. Around 85 more products are planned to launch
        in the future.
      </p>
    </div>
  </main>
);

export default about;

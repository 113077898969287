import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Products from "../ProductsPage/ProductsPage";
import Productdetail from "../ProductsPage/ProductDetail";
import About from "../Inner-static-pages/About";
import Contact from "../Inner-static-pages/Contact";
import Blogs from "../Inner-static-pages/Blogs";
import WhatWeDo from "../Inner-static-pages/What-we-do";
import Services from "../Inner-static-pages/Services";
import Home from "../Home";
import NotFoundPage from "../Random/PageNotFound";

const sideDrawer = (props) => {
  let drawerClass = ["side-drawer"];

  if (props.show) {
    drawerClass = "side-drawer side-drawer-open";
  }

  return (
    <Router>
      <div>
        <nav className={drawerClass}>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about-us">About us</a>
            </li>
            <li>
              <a href="/what-we-do">What we do</a>
            </li>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact-us">Contact us</a>
            </li>
            <li>
              <a href="/blogs">Blogs</a>
            </li>
          </ul>
        </nav>
      </div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route exact path="/about-us">
          <About />
        </Route>
        <Route exact path="/contact-us">
          <Contact />
        </Route>
        <Route exact path="/what-we-do">
          <WhatWeDo />
        </Route>
        <Route exact path="/blogs">
          <Blogs />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route exact path="/product/:productId">
          <Productdetail />
        </Route>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default sideDrawer;

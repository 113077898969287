import React from "react";

const Services = (props) => {
  return (
    <main style={{ marginTop: "80px" }}>
      <div className="container">
        <h1>Services</h1>

        <div className="row">
          <div className="col-md-12">
            <p>
              Pathaakin offers spiritual corrections facility. After a lot of
              observations and research in the dystopian educated youth
              population, some serious life-threatening problems couldn’t be
              solved by the secular system like law and order, judiciary,
              healthcare, money, etc. because these problems were beyond the
              imagination and control of the secular order.
            </p>
            <p>
              {" "}
              Hindu families with a deep absence of religious order began to
              suffer from so many invisible and irreparable damages. The
              negative spirits were attacking the mentally weak mined people
              including the age group of infants, to nurture cult values through
              addiction, crime, corruption and other sinful activities (Which is
              also known as Vashikaran).
            </p>
            <p>
              {" "}
              And those families had no protection even after visiting some of
              the world-famous temples, offering poojas and sevas. Pathaakin
              carefully studied this spiritual void by critically diagnosing
              hidden problems and tested some pilot projects by imparting
              spiritual correction solutions on the affected families to come
              out of the misery.{" "}
            </p>
            <p>
              Pathaakin received a lot of positive praising testimonials from
              the family members to see the unbeatable and unthinkable changes
              with positive vibes in facing the various adaptive challenges. The
              results were amazing. To note a few of them are, restoring damaged
              relationships, career success, Rehabilitation from addictive
              lifestyle, mental health and peace, elimination of black magic,
              erasing evil enslavement and diffusing entrapment.{" "}
            </p>
            <p>
              It was found that a single prayer or pooja method didn’t work for
              different tamasic problems and had to approach with a specific
              tantric method by certified experts in the area of astrology,
              Shilpa Shastra and Vastu-shastra.
            </p>
            <br />

            <h3>Our quality focused services</h3>
            <br />
            <strong>
              <p>
                We have four levels of services ranging from the simple to
                deep-rooted spiritual voids.
              </p>
            </strong>
            <ul>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="silver">Silver</span>
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  {" "}
                  Pathaakin <span className="gold">Gold</span>
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="diamond">Diamond</span>
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  {" "}
                  Pathaakin <span className="platinum">Platinum</span>
                </p>
              </li>
            </ul>
            <br />
            <ul>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="silver">Silver</span>&nbsp;offers
                  only a detailed astrological report generation with the most
                  accurate and precise scientific prediction. The client can
                  approach the respective astrologer within his/her locality and
                  follow the guidance. We offer Child Kundli Reports, Rajayoga
                  Reports and Bruhat Kundli Reports with Lal Kitab calculations
                  in Both North-Indian and SouthIndian Kundli formats.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="gold">Gold&nbsp;</span> offers a
                  two-phased solution where the generated astrological report
                  will be linked to the specific Brass figurine that will be
                  available for purchase in e-shopping portals like Amazon,
                  Flipkart and snapdeal.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="diamond">Diamond&nbsp;</span>{" "}
                  offers an additional phase along with Pathaakin GOLD. Here the
                  client will be offered a detailed Astrological Report,
                  Guidance from the various experts through telephone or video
                  conference and can ask an unlimited number of doubts and
                  questions based on the generated astrological report. And the
                  allied brass figurine will be delivered through amazon to
                  offer pooja and prayer at home.
                </p>
              </li>
              <li style={{ listStyleType: "square" }}>
                <p>
                  Pathaakin <span className="platinum">Platinum&nbsp;</span>{" "}
                  (i-Cube) is a dedicated Spiritual corrections facility package
                  offered by the Pathaakin Expert Panel Committee (P.E.P.C). The
                  experts are certified professionals from institutes like
                  Vishwakarma Institute of Astrology and Research Centre ®
                  Bangalore, And Vastu consultants from ISD (International
                  School of Design, Kolkata). All the assigned experts will
                  visit the client’s location, make an in-depth analysis of all
                  the spiritual voids. Every diagnosed problem will undergo
                  thorough process documentation and a permanent solution will
                  be designed as per the gravity of problem integrating with
                  authentic Vedic principles, Vastu shastra, bruhat Kundli,
                  black magic remedy, etc. with a tested model called
                  Intelligent Information Integration (i-Cube) within 11 days.
                  If inevitable, the chosen brass figurine will be Life
                  consecrated to protect the entire family space from the
                  destructive spirits. The expert team will be ready to travel
                  any part of India.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="enquiry-section">
              <a
                className="whatsapp_link"
                href="https://api.whatsapp.com/send?phone=918310201121&text=https%3A%2F%2Fwww.facebook.com%2Fpathaakin%2F%0A%0AI%27m+interested+in+learning+more+about+Pathaakin+Figurines."
              >
                <span></span>Chat with us
              </a>
              <br />
              <a
                className="enquire_mail"
                href="mailto:sales@pathaakin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span></span>Enquire Now
              </a>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-warning" role="alert">
              <h3 style={{ color: "rgb(168, 0, 0)", textAlign: "center" }}>
                Disclaimer
              </h3>
              <p>
                {" "}
                All the services are inclusive of 18% GST and offer high
                confidentiality and probability of success. But cannot be
                assured a 100% guarantee, in case of wrong details or erroneous
                information provided by the client-side. We want to make it
                clear that we put our best efforts in providing the
                above-mentioned services but any prediction that you receive
                from us is not to be considered as a substitute for advice,
                program, or treatment, that you would normally receive from a
                licensed professional such as a lawyer, doctor, psychiatrist, or
                financial advisor. Although we try our best to give you accurate
                calculations, we do not rule out the possibility of errors. The
                services provided as-is and we provide no guarantees, implied
                warranties, or assurances of any kind, and will not be
                responsible for any interpretation made or use by the recipient
                of the information and data mentioned above. If you are not
                comfortable with service or astrological information, please do
                not use it.
              </p>
            </div>
            <br />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Services;

import React from "react";

const Preheader = () => {
  return (
    <div className="container">
      {" "}
      <ul className="preheader-links">
        <li>
          <a className="phone-call" href="tel:+91-820-3500030">
            +91-820-3500030
          </a>
        </li>
        <li>
          <a className="mail-icon" href="mailto:sales@pathaakin.com">
            sales@pathaakin.com
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Preheader;

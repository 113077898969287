import React from "react";
import Social from "../Random/SocialBlocks";

export default function Footerright() {
  return (
    <div
      className="contact-footer"
      data-aos="fade"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out-cubic"
    >
      <Social />
    </div>
  );
}

import React from "react";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import GMap from "./../Map/GoogleMap";

const Footer = (props) => {
  return (
    <div className="container-fluid footer">
      <div className="container ">
        <div className="row">
          <div className="col-md-4 text-center">
            <h4 className="vertical-spacer-sm">Pathaakin Figurines</h4>
            <FooterLeft />
          </div>
          <div className="col-md-4">
            <h4>Contact Us</h4>
            <FooterRight />
          </div>
          <div className="col-md-4 text-center">
            <h4>Address</h4>
            <GMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
// import Typist from "react-typist";

export default function Homepagestatic() {
  return (
    <div className="container">
      <div className="row section-bg">
        <div className="col-md-6">
          <div
            className="static_text_section vertical-spacer-sm"
            data-aos="fade-down"
            data-aos-duration="600"
            data-aos-easing="ease-in-out-cubic"
          >
            <h3>Vision</h3>
            <div className="vision vertical-spacer-sm">
              To Attain Flagship in the Antique Brass Figurines.
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="static_text_section vertical-spacer-sm"
            data-aos="fade-down"
            data-aos-duration="220"
            data-aos-easing="ease-in-out-cubic"
          >
            <h3>Mission</h3>
            <div className="mission vertical-spacer-sm">
              To Deliver High Quality Brass Figurines with a Value-Driven Supply
              Chain Model.
            </div>
          </div>
        </div>
      </div>

      {/* About Pathaakin */}
      <div className="row vertical-spacer" style={{ marginTop: 50 }}>
        <div
          className="col-md-8"
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-easing="ease-in-out"
        >
          <p>
            Welcome to the world of Pathaakin, a True Destiny of Design In
            Divination. Pathaakin is a Sanskrit word that means flagged,
            decorated or adorned with flags to make something more elegant and
            beautiful. <br />
            India is the land of Rich cultural heritage with an unthinkable
            variety of arts in the form of sculpture, paintings, pottery and
            artifacts. Pathaakin plays a significant role in promoting the art
            of antique brass figurines. Pathaakin wants to impart the
            everlasting impression by delivering high quality antique brass
            items. The beauty of any Indian Art is visualized with a sacred
            devotion towards the glory of spiritual wisdom. <br />
            Pathaakin strives to integrate the artistic beauty and wisdom by
            providing high quality brass figurines with a superior Quality
            Management Policy. Let us explore the Art with Grace!
          </p>
        </div>
        <div
          className="col-md-4"
          data-aos="fade-down"
          data-aos-duration="400"
          data-aos-easing="ease-in-out"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={require("../../assets/Icons/pathaakin-new.png")}
            style={{ objectFit: "cover", borderRadius: 10 }}
            alt="img"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Data from "../../assets/data/Products.json";
import { Table } from "reactstrap";
class Blogs extends React.Component {
  render() {
    return (
      <main style={{ marginTop: 80, marginBottom: 50 }}>
        <div className="container">
          <h1 className="vertical-spacer-sm">Links to Wikipedia.</h1>
          <div className="wiki-links">
            <Table bordered responsive hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Product Name</th>
                  <th>Wikipedia Link</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((prod) => (
                  <tr key={prod.id}>
                    <td>{prod.id}</td>
                    <td>{prod.Product_Name}</td>
                    <td>
                      <a
                        href={prod.God_Information}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {prod.God_Information}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </main>
    );
  }
}

export default Blogs;

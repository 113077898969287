import React from "react";
import Data from "../../assets/data/data.json";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Parallax,
  Pagination,
  Navigation,
  Autoplay,
  EffectFlip,
} from "swiper";
import "swiper/swiper.scss";
import "swiper/components/effect-flip/effect-flip.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/autoplay";
import Typist from "react-typist";

SwiperCore.use([Parallax, Navigation, Pagination, Autoplay, EffectFlip]);

const Herosection = (props) => {
  return (
    <div
      className="container-fluid vertical-spacer"
      data-aos="fade"
      data-aos-duration="2000"
      data-aos-easing="ease-in-out-cubic"
    >
      <div className="row hero-row">
        <div className="hero-image">
          <Swiper
            slidesPerView={1}
            autoplay
            pagination
            flipEffect
            loop
            navigation
          >
            {Data.map((img) => {
              return (
                <SwiperSlide key={img.id}>
                  <img
                    src={require("../../assets/Images/" + img.image)}
                    alt={img.alt}
                    width="100%"
                    className="hero_img"
                  />
                </SwiperSlide>
              );
            })}
            <Typist>
              <h2 className="hero_txt">
                Welcome to
                <br /> <span>Pathaakin Figurines.</span>
              </h2>
            </Typist>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Herosection;

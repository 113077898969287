import React, { Component } from "react";
import Typist from "react-typist";

export default class PageNotFound extends Component {
  render() {
    return (
      <div className="container" style={{ marginTop: 80 }}>
        <Typist className="text-center">
          <h1 style={{ fontSize: 70 }}>
            404 Page not found.
            <br /> It looks nothing is here. <br />
            Lets go back to&nbsp;
            <br />
            <a href="/">Pathaakin Figurines.</a>
          </h1>
        </Typist>
      </div>
    );
  }
}

import React from "react";
import Social from "../Random/SocialBlocks";

function Contact() {
  return (
    <main style={{ marginTop: "80px" }}>
      <div className="container">
        <h1>Contact us</h1>

        <div className="row">
          <div className="col-md-12">
            <p style={{ fontSize: "25px", lineHeight: "15px" }}>
              Registered office:
            </p>
            <p>Shop No.8, 7-3-13J7, Ground Floor <br></br>
              Bhoovaraha Complex, Udupi-576101<br></br>
              KARNATAKA, INDIA
            </p>
            <hr />
            <p style={{ fontSize: "25px", lineHeight: "15px" }}>Phone: </p>
            <p>
              <a href="tel:+91-820-3500030">+91-820-3500030</a>&nbsp;(Off.)
              <br></br>
              <a href="tel:+91-8310201121">+91-8310201121</a>&nbsp;(Cell)
            </p>
            <hr />
            <p>
              <strong>GSTIN:</strong> 29ANCPB8881B1Z2
            </p>
            <p>
              <strong>Registered Trade Mark Number:</strong> 4459127
            </p>
            <hr />
            <p>
              For Customer Feedback and Support:&nbsp;
              <a href="mailto:sales@pathaakin.com">sales@pathaakin.com</a>
              <br />
              For General enquiries:{" "}
              <a href="mailto:info@pathaakin.com">info@pathaakin.com</a>
              <br />
              For any technical queries:{" "}
              <a href="mailto:webadmin@pathaakin.com">webadmin@pathaakin.com</a>
            </p>
            <hr />
            <Social />
            <hr />
            <div className="row">
              <div className="col-md-12">
                <iframe
                  src="https://contacts.thehaystackapp.com/items/sLDNbrfg/?exchange=false"
                  width="100%"
                  height="500px"
                  title="contact"
                  style={{ border: "none" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Contact;

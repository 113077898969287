import React, { Component } from "react";
import DarkOverlay from "./components/DarkOverlay/DarkOverlay";
import Preheader from "./components/Header/Preheader";
import Header from "./components/Header/Header";
import SideDrawer from "./components/Header/SideDrawer";
import Footer from "./components/Footer/Footer";
import FooterBottom from "./components/Footer/FooterBottom";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "react-scroll-to-top";

AOS.init();

class App extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  darkOverlayClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let darkOverlay;

    if (this.state.sideDrawerOpen) {
      darkOverlay = <DarkOverlay click={this.darkOverlayClickHandler} />;
    }
    return (
      <>
        <ScrollToTop />
        <Preheader />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {darkOverlay}
        <Header drawerClickHandler={this.drawerToggleClickHandler} />
        <Footer />
        <FooterBottom />
      </>
    );
  }
}

export default App;

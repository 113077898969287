import React from "react";
import ProdData from "../../assets/data/Products.json";
import PageNotFound from "../Random/Blankpage";
import Img from "react-cool-img";

class ProductDetail extends React.Component {
  render() {
    return (
      <main style={{ marginTop: 80 }} className="vertical-spacer">
        <div className="container vertical-spacer">
          {ProdData.map((prod) => {
            let id = window.location.pathname;
            let array = id.split("/");
            let url = array[array.length - 1];
            if (prod.id === url) {
              return (
                <div className="row product-details" tabIndex="1" key={prod.no}>
                  <div className="col-md-5" key={prod.no}>
                    <div
                      className="image-container"
                      style={{ marginTop: 30 }}
                      data-aos="fade-down"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      <Img
                        placeholder={require("../../assets/Icons/loading.svg")}
                        src={require("../../assets/Images" + prod.image)}
                        alt={prod.alt}
                        debounce={1000}
                        className="product_image"
                      />
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div
                    className="col-md-6"
                    data-aos="fade-down"
                    data-aos-duration="900"
                    data-aos-easing="ease-in-out-cubic"
                  >
                    <div className="details-container">
                      <h3 style={{ marginTop: 30 }}>{prod.Product_Name}</h3>
                      <br />
                      <h5>
                        <b>Height:</b> {prod.Height}
                      </h5>
                      <h5>
                        <b>Length:</b> {prod.Length}
                      </h5>
                      <h5>
                        <b>Depth: </b>
                        {prod.Depth}
                      </h5>
                      <h5>
                        <b>Weight:</b> {prod.Weight}
                      </h5>
                      <h5>
                        <b>Metal Type:</b> {prod.Metal_Type}
                      </h5>
                      <h5>
                        <b>Polish:</b> {prod.Polish}
                      </h5>
                      <h5>
                        <b>Colour:</b> {prod.Colour}
                      </h5>
                      <h5>
                        <b>Durability:</b> {prod.Durability}
                      </h5>
                      <h5>
                        <b>MRP: </b> ---
                      </h5>
                      <hr />
                      <div className="enquiry-section">
                        <a
                          className="whatsapp_link"
                          href="https://api.whatsapp.com/send?phone=918310201121&text=https%3A%2F%2Fwww.facebook.com%2Fpathaakin%2F%0A%0AI%27m+interested+in+learning+more+about+Pathaakin+Figurines."
                        >
                          <span></span>Chat with us
                        </a>
                        <br />
                        <a
                          className="enquire_mail"
                          href="mailto:sales@pathaakin.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span></span>Enquire Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return <PageNotFound />;
            }
          })}
        </div>
      </main>
    );
  }
}

export default ProductDetail;

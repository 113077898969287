import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import WebFont from "webfontloader";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

WebFont.load({
  google: {
    families: ["Nunito:200,300,400,500,700,800", "sans-serif"],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();

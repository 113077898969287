import React from "react";

export default function Footerleft() {
  return (
    <div
      className="footer-logo"
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out-cubic"
    >
      <img
        src={require("../../assets/Icons/PATHAAKIN-logo.jpg")}
        alt="Footer-logo"
        className="footer-img"
      />
    </div>
  );
}

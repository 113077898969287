import React from "react";
import Hambutton from "./HamButton";
import Preheader from "./Preheader";

const header = (props) => (
  <>
    <div className="container-fluid preheader">
      <Preheader />
    </div>

    <header className="header">
      <nav className="container header__nav">
        <div>
          <Hambutton click={props.drawerClickHandler} />
        </div>
        <div className="header__logo">
          <a href="/">
            <img src={require("../../assets/Icons/logo-1.svg")} alt="logo" />
          </a>
        </div>
        <div className="spacer"></div>
        <div className="nav-link">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about-us">About us</a>
            </li>
            <li>
              <a href="/what-we-do">What we do</a>
            </li>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact-us">Contact us</a>
            </li>
            <li>
              <a href="/blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </>
);

export default header;

import React, { Component } from "react";
import Herosection from "./HeroSection/HeroSection";
import StaticText from "./Sections/HomePageStatic";
import Video from "./Random/YoutubeEmbed";
import "../scss/Styles.scss";

export default class Home extends Component {
  render() {
    return (
      <div style={{ height: "100%" }}>
        <main style={{ marginTop: "55px" }}>
          <Herosection />
          <StaticText />
          <Video />
        </main>
      </div>
    );
  }
}

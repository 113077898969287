import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faFacebook,
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faMailBulk, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

export const SocialBlocks = () => {
  library.add(fab, faFacebookF);

  return (
    <div className="text-center">
      <ul className="social-icons">
        <li>
          <a href="https://www.facebook.com/pathaakin">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://twitter.com/pathaakin">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=918310201121&text=https%3A%2F%2Fwww.facebook.com%2Fpathaakin%2F%0A%0AI%27m+interested+in+learning+more+about+Pathaakin+Figurines.">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          <a href="tel: +91 8310201121">
            <FontAwesomeIcon icon={faMobileAlt} size="2x" />
          </a>
          <a href="mailto: info@pathaakin.com">
            <FontAwesomeIcon icon={faMailBulk} size="2x" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialBlocks;

import React from "react";
import Data from "../../assets/data/Products.json";
import Img from "react-cool-img";

class productsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedItem: "",
    };

    this.toggle = this.toggle.bind(this);
  }

  handleShowModal = (title) => {
    this.setState(this.toggle);
    if (this.state.modal === false) {
      this.setState({
        selectedItem: title,
      });
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    return (
      <main style={{ marginTop: "80px" }}>
        <div className="container">
          <h1>Our Products</h1>
          <div className="row vertical-spacer">
            {Data.map((post) => (
              <div className="col-md-3 col-xs-6" key={post.id}>
                <div key={post.id} className="card">
                  <Img
                    placeholder={require("../../assets/Icons/loading.svg")}
                    src={require("../../assets/Images" + post.image)}
                    alt={post.alt}
                    debounce={1000}
                    className="product_card_image"
                  />

                  <span className={post.badge}></span>
                  <div className="card_text_section">
                    <div className="card-title">{post.Product_Name}</div>
                    <p className="card-content">
                      <b>Prod. ID:</b>&nbsp;{post.id}
                    </p>
                    <p className="card-content">
                      <b> Material:</b>&nbsp;{post.Metal_Type}
                    </p>
                    <div className="button_container">
                      <a
                        href={"/product/" + post.id}
                        className="buy_button"
                        key={post.id}
                      >
                        Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    );
  }
}
export default productsPage;
